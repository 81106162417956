import { isAbsent, isPresent } from "./value-helpers"

export function joinBySpace(array = []) {
  if (Array.isArray(array)) {
    return array.join(` `)
  }
  return ``
}

/**
 * Returns the middle Index for an Array
 *
 * @param {Array} array
 * @param {Number} offset If the array is odd lengthed, whether to determine middle is above or below fractional middle
 * @returns The middle index of an array
 */
export function getMiddleIndex(array = [], offset = 0) {
  if (Array.isArray(array)) {
    const middleIndex = Math.ceil(array.length / 2)

    return array.length % 2 === 1 ? middleIndex + offset : middleIndex
  }

  return 0
}

export function splitStringByBreakTag(value, offset = 0) {
  if (isAbsent(value) || value.length < 16) {
    return value
  }
  const words = value.trim().split(/\s/)
  if (words.length === 1) {
    return value
  }
  const middleIndex = getMiddleIndex(words, 0)
  return (
    joinBySpace(words.slice(0, middleIndex)) +
    `<br />` +
    joinBySpace(words.slice(middleIndex))
  )
}

export function isArrayLongerThanCurrentIndex(array, currentIndex) {
  const index = Number.parseInt(currentIndex)
  return isPresent(array) && Array.isArray(array) && array.length - 1 > index
}
