import { isPresent } from "./value-helpers"

const EMPTY_PRODUCT_NAME = `-`

export function progressProductTitleFromContentfulProduct(product) {
  if (
    isPresent(product?.productName) &&
    product.productName !== EMPTY_PRODUCT_NAME
  ) {
    return `${product.productCode} <br/>${product.productName}`
  }
  return product.productCode
}

export function productNameFromContentfulProduct(product) {
  return isPresent(product?.productName) &&
    product.productName !== EMPTY_PRODUCT_NAME
    ? product.productName
    : product?.productDisorder
}

export function productNameOrDefault(productName) {
  return isPresent(productName) && productName !== EMPTY_PRODUCT_NAME
    ? productName
    : `—`
}
