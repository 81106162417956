// import React from "react"
import styled from "styled-components"
import { rem } from "polished"

const Container = styled.div`
  width: ${rem(1260)};
  margin: 0 auto;

  @media ${(props) => props.theme.smallUp} {
    max-width: 90%;
  }
  @media ${(props) => props.theme.smallDown} {
    max-width: 100%;
    padding-left: ${rem(20)};
    padding-right: ${rem(20)};
  }
`

export default Container
