import React from "react"
import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Link = ({ children, to, activeClassName, title, ...other }) => {
  const isInternal = /^\/(?!\/)/.test(to)
  const hasAnchor = isInternal && /[#]/.test(to)

  if (hasAnchor) {
    return (
      <AnchorLink
        to={to}
        title={title}
        gatsbyLinkProps={{ ...other, activeClassName }}
      >
        {children}
      </AnchorLink>
    )
  }

  if (isInternal) {
    return (
      <GatsbyLink to={to} activeClassName={activeClassName} {...other}>
        {children}
      </GatsbyLink>
    )
  }

  if (other.target && !other.rel) {
    other.rel = `noopener noreferrer`
  }

  return (
    <a href={to} {...other}>
      {children}
    </a>
  )
}

Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
  activeClassName: PropTypes.string,
  title: PropTypes.string,
}

export default Link
