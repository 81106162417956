import React from "react"

const LogoIcon = (props) => (
  <svg
    width="45"
    height="53"
    viewBox="0 0 45 53"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15.8292 12.9558V17.1932H18.6662C19.581 17.1932 20.3213 17.9352 20.3213 18.8521V40.5132C15.7076 39.5354 12.2335 35.4225 12.2335 30.5094V17.1958V14.461C12.2335 8.8245 16.8101 4.23735 22.4364 4.23735C26.2939 4.23735 29.657 6.39445 31.3888 9.5665H36.0156C34.0142 3.98825 28.692 0 22.4364 0C14.9752 0 8.83602 5.6763 8.08514 12.9558H0V17.1932H8.00583V30.5094C8.00583 37.7784 13.3545 43.7912 20.3213 44.8168V53H24.5489V44.8195V40.5158V18.8547C24.5489 17.9378 25.2892 17.1958 26.204 17.1958H44.8702V12.9558H15.8292Z" />
    <path d="M27.9329 39.1188V43.8862C33.1758 41.7185 36.8667 36.5483 36.8667 30.5116H32.6391C32.6391 34.1209 30.7592 37.2983 27.9329 39.1188Z" />
  </svg>
)

export default LogoIcon
