import React from "react"
import PropTypes from "prop-types"
import { rem } from "polished"

import styled, { css } from "styled-components"

const Container = styled.div`
  // border: 1px solid red;

  ${(props) =>
    props.top &&
    css`
      @media ${(props) => props.theme.smallUp} {
        padding-top: ${rem(75)};
      }

      @media ${(props) => props.theme.smallDown} {
        padding-top: ${rem(35)};
      }
    `}

  ${(props) =>
    props.bottom &&
    css`
      @media ${(props) => props.theme.smallUp} {
        padding-bottom: ${rem(75)};
      }

      @media ${(props) => props.theme.smallDown} {
        padding-bottom: ${rem(35)};
      }
    `}
`

function Spacer(props) {
  return (
    <Container top={props.top} bottom={props.bottom}>
      {props.children}
    </Container>
  )
}

export default Spacer

Spacer.propTypes = {
  top: PropTypes.bool,
  bottom: PropTypes.bool,
  children: PropTypes.node,
}
