import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import initSmartOutline from "@oddcamp/js-utils/src/smart-outline"
import smoothscroll from "smoothscroll-polyfill"
import { CookiesConsent } from "../components/legacy-cookies-consent"

import { StoreProvider, StoreConsumer } from "../store"

import "../config/yup"

import Header from "../components/header/"
import { Footer } from "../components/footer/footer"

import TagManager from "react-gtm-module"
import theme from "../theme"

import "../assets/stylesheets/fonts/woff.scss"
import "../assets/stylesheets/app/app.scss"
import { isBrowser } from "../utils/is-browser"

if (isBrowser()) {
  smoothscroll.polyfill()
}

if (typeof document !== `undefined`) {
  initSmartOutline([
    `input:focus`,
    `button:focus`,
    `textarea:focus`,
    `select:focus`,
  ])
}

// Feature Flag for TrustArc Cookies Consent
const isTrustArcEnabled = process.env.GATSBY_ENABLE_TRUSTARC === `true`

function Layout(props) {
  React.useEffect(() => {
    if (typeof document !== `undefined`) {
      const tagManagerArgs = {
        gtmId: `GTM-T4CLNCT`,
      }
      TagManager.initialize(tagManagerArgs)
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <StoreProvider>
        <StoreConsumer>
          {({ headerTheme }) => <Header headerTheme={headerTheme} />}
        </StoreConsumer>

        <main>{props.children}</main>
        {!isTrustArcEnabled && <CookiesConsent />}

        <Footer />
      </StoreProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
