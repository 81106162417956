import { Component } from "react"
import PropTypes from "prop-types"

import { getSessionStorage } from "./utils/get-session-storage"
import { CoreError } from "./core-error"
import { isBrowser } from "./utils/is-browser"

const INITIAL_STATE = {}

export class CoreErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = INITIAL_STATE
    this.getPathname = this.getPathname.bind(this)
  }

  getPathname() {
    return isBrowser() ? window.location.pathname : `unknown`
  }

  componentDidCatch(error, info) {
    const sessionStorage = getSessionStorage()
    if (
      error instanceof Error &&
      // https://github.com/gatsbyjs/gatsby/issues/33956
      error.message.includes(
        `The result of this StaticQuery could not be fetched`
      )
    ) {
      const pathname = this.getPathname()
      this.setState((prevState) => ({ ...prevState, [pathname]: error }))

      if (
        typeof sessionStorage !== `undefined` &&
        sessionStorage.getItem(`${pathname}_hasReloadedFromError`) !== `true`
      ) {
        sessionStorage.setItem(`${pathname}_hasReloadedFromError`, `true`)
        // eslint-disable-next-line no-console -- will be caught by sentry
        console.error(
          `useStaticQuery error. Page to reload once.`,
          error,
          info.componentStack
        )
        window?.location.reload(true) // only Firefox takes param, others ignore
      }
    } else {
      // Propagate any other error to the next error boundary
      throw new CoreError(error.message, error.stack, info)
    }
  }

  render() {
    const pathname = this.getPathname()

    return this.state[pathname] ? null : this.props.children
  }
}

CoreErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}
