import React from "react"
import PropTypes from "prop-types"

const StoreContext = React.createContext()
const StoreConsumer = StoreContext.Consumer

function StoreProvider(props) {
  const [theme, setTheme] = React.useState({ headerTheme: `bright` }) // bright | dark

  const value = React.useMemo(
    () => ({
      headerTheme: theme.headerTheme,
      setHeaderTheme: (headerTheme) => setTheme({ headerTheme }),
    }),
    [theme.headerTheme]
  )

  return (
    <StoreContext.Provider value={value}>
      {props.children}
    </StoreContext.Provider>
  )
}

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { StoreContext, StoreProvider, StoreConsumer }
