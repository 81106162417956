export function isAbsent(value) {
  return typeof value === `undefined` || value === null
}

export function isPresent(value) {
  return typeof value !== `undefined` && value !== null
}

export function isEmptyString(value) {
  return typeof value === `string` && value.length === 0
}
