import React from "react"
import styled from "styled-components"
import { rem } from "polished"

import Container from "../container"
import Spacer from "../spacer"
import Link from "../link"

import { FooterProducts } from "./footer-products"

const FooterEl = styled.footer`
  background: #333;
  color: ${(props) => props.theme.colorWhite};

  @media (max-width: 767px) ${(props) => props.theme.smallDown} {
    padding-left: ${rem(20)};
    padding-right: ${rem(20)};
    display: none;
  }
`

const Bottom = styled(Container).attrs({ className: `styled-p` })`
  padding-bottom: ${rem(40)};

  @media ${(props) => props.theme.largeUp} {
    display: flex;
    justify-content: space-between;
  }

  ul {
    @media ${(props) => props.theme.largeUp} {
      display: flex;
    }

    @media ${(props) => props.theme.largeDown} {
      text-align: center;
    }
  }

  div:nth-of-type(1) li:not(:last-of-type) {
    @media ${(props) => props.theme.largeUp} {
      margin-right: ${rem(16)};
    }
  }

  a {
    @media ${(props) => props.theme.xlargeUp} {
      position: relative;
      overflow: hidden;

      &::after {
        content: "";
        position: absolute;
        top: 100%;
        margin-top: 3px;
        left: 0;
        width: 100%;
        height: 1px;
        background: currentColor;
        opacity: 0.4;
        transition: transform 0.5s, transform-origin 0s 0s;
        transform: scaleX(0);
        transform-origin: right;
      }

      &:hover,
      &.active {
        &::after {
          transform-origin: left;
          transform: scaleX(1);
          transition: transform 0.5s 0s, transform-origin 0s 0s;
        }
      }
    }
  }
`

const SocialMedia = styled.div`
  @media ${(props) => props.theme.largeUp} {
    display: flex;
  }

  @media ${(props) => props.theme.largeDown} {
    padding-top: ${rem(30)};
    text-align: center;
  }

  ul {
    @media ${(props) => props.theme.largeDown} {
      padding-top: ${rem(20)};
    }

    li {
      @media ${(props) => props.theme.largeUp} {
        margin-left: ${rem(16)};
      }
    }
  }
`

const Text = styled.span`
  opacity: 0.5;
`

const Top = styled(Container)`
  justify-content: space-between;
  display: flex;

  @media ${(props) => props.theme.largeDown} {
    flex-wrap: wrap;
    margin-bottom: ${rem(-40)};
  }
`

export function Footer(props) {
  const content = {
    bottomLinks: [
      {
        text: `Contact us`,
        url: `/#contact_us`,
      },
      {
        text: `Press`,
        url: `/press`,
      },
      {
        text: `Terms of Use`,
        url: `/terms.html`,
      },
      {
        text: `Privacy Policy`,
        url: `/privacy.html`,
      },
    ],
    socialMediaLinks: [
      {
        text: `Twitter`,
        url: `https://twitter.com/Click_Tx`,
      },
      {
        text: `Facebook`,
        url: `https://facebook.com/ClickTherapeutics`,
      },
      {
        text: `Linkedin`,
        url: `https://linkedin.com/company/click-therapeutics-inc-`,
      },
      {
        text: `Instagram`,
        url: `https://www.instagram.com/click_tx/`,
      },
    ],
  }

  return (
    <FooterEl>
      <Spacer top bottom>
        <Top>
          <FooterProducts />
        </Top>
      </Spacer>

      <Bottom>
        <div>
          <ul>
            {content.bottomLinks.map((item, index) => (
              <li key={`bottom-link-${index}`}>
                <Link to={item.url} title={item.text}>
                  {item.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <SocialMedia>
          <Text>Follow us on:</Text>
          <ul>
            {content.socialMediaLinks.map((item, index) => (
              <li key={`social-media-${index}`}>
                <Link to={item.url} target="_blank">
                  {item.text}
                </Link>
              </li>
            ))}
          </ul>
        </SocialMedia>
      </Bottom>

      <Bottom>
        <div>
          <ul>
            <Text>
              © <span>{new Date().getFullYear()}</span> Click Therapeutics, Inc.
              All Rights Reserved.
            </Text>
          </ul>
        </div>
      </Bottom>
    </FooterEl>
  )
}
