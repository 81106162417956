import * as React from "react"
import PropTypes from "prop-types"
import { useMediaQuery } from "react-responsive"

import Menu from "./menu"
import MenuContainer from "./menu-container"
import MenuTrigger from "./menu-trigger"
import themeMQ from "../../theme/sections/mq"

const Header = (props) => {
  const [menuOpen, menuToggle] = React.useState(false)
  const isMobile = useMediaQuery({ query: themeMQ.xlargeDown })

  const Links = {
    left: [
      {
        text: `Digital Therapeutics`,
        url: `/digital-therapeutics`,
      },
      {
        text: `For Patients`,
        url: `/for-patients`,
      },
      {
        text: `For Providers`,
        url: `/for-providers`,
      },
      {
        text: `Clinical Trials`,
        url: `/clinical-trials`,
      },
    ],
    right: [
      {
        text: `Products & Pipeline`,
        url: `/products`,
      },
      {
        text: `Press`,
        url: `/press`,
      },
      {
        text: `Publications`,
        url: `/publications`,
      },
      {
        text: `Team`,
        url: `/team`,
      },
      {
        text: `Careers`,
        url: `/careers`,
      },
    ],
  }

  return (
    <MenuContainer colorTheme={props.headerTheme}>
      <Menu menuToggle={menuToggle} open={menuOpen && isMobile} links={Links} />

      <MenuTrigger menuOpen={menuOpen && isMobile} menuToggle={menuToggle} />
    </MenuContainer>
  )
}

Header.propTypes = {
  headerTheme: PropTypes.string,
}

export default Header
