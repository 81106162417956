import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { rem } from "polished"

const MenuTriggerEl = styled.button`
  position: absolute;
  z-index: 2;
  transition: transform 0.5s 0s;
  top: 50%;
  overflow: visible;

  @media ${(props) => props.theme.xlargeUp} {
    display: none;
  }

  @media ${(props) => props.theme.xlargeDown} {
    display: block;
    width: ${rem(20)};
    width: 20px;
    height: ${rem(15)};
    height: 15px;
    right: ${rem(20)};
    margin-top: ${rem(-8)};
  }

  span {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    background: currentColor;

    height: 1px;

    :nth-of-type(1) {
      transition: all 0.3s 0.3s, transform 0.3s 0s;
    }

    :nth-of-type(2) {
      top: 50%;
      top: ${rem(7)};
      top: 7px;
      left: 0;
      transition: all 0.3s 0.3s;
    }

    :nth-of-type(3) {
      bottom: 0;
      top: auto;
      left: 0;
      transition: all 0.3s 0.3s, transform 0.3s 0s;
    }
  }

  ${(props) =>
    props.open &&
    css`
      transition: all 0.5s 0.3s;

      @media ${(props) => props.theme.xlargeDown} {
        transform: rotate(135deg) scale(0.75);
        transform: rotate(135deg) scale(1);
      }

      span:nth-of-type(1) {
        top: ${rem(7)};
        top: 7px;
        transform: rotate(90deg);
        transition: all 0.3s, transform 0.3s 0.3s;
      }

      span:nth-of-type(2) {
        opacity: 0;
        transition: all 0.3s, opacity 0.3s 0s;
      }

      span:nth-of-type(3) {
        bottom: ${rem(7)};
        bottom: 7px;
        transform: rotate(0deg);
        transition: all 0.3s, transform 0.3s 0.3s;
      }
    `}
`

const MenuTrigger = (props) => {
  const { menuOpen, menuToggle } = props
  const handleMenuClick = React.useCallback(() => {
    menuToggle((prevMenuOpen) => !prevMenuOpen)
  }, [menuToggle])
  return (
    <MenuTriggerEl
      onClick={handleMenuClick}
      open={menuOpen}
      aria-label={menuOpen ? `Close menu` : `Open menu`}
    >
      {[1, 2, 3].map((i) => (
        <span key={`menu-trigger-${i}`}></span>
      ))}
    </MenuTriggerEl>
  )
}

export default MenuTrigger

MenuTrigger.propTypes = {
  menuToggle: PropTypes.func,
  menuOpen: PropTypes.bool,
}
