import React from "react"

const LogoText = (props) => (
  <svg
    width="137"
    height="44"
    viewBox="0 0 137 44"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.57538 4.55032C2.239 3.23592 3.18553 2.21832 4.41496 1.49752C5.64439 0.776716 7.06947 0.413666 8.68756 0.413666C10.5648 0.413666 12.1379 0.925116 13.4096 1.94537C14.6787 2.96827 15.4772 4.36217 15.7997 6.12972H13.6872C13.3805 4.94517 12.7777 4.02032 11.8788 3.35517C10.9798 2.69002 9.88526 2.35877 8.58973 2.35877C7.42376 2.35877 6.39791 2.64232 5.5069 3.20942C4.6159 3.77652 3.93377 4.56887 3.45521 5.58117C2.97666 6.59612 2.73871 7.75947 2.73871 9.07122C2.73871 10.3697 2.97666 11.5278 3.45521 12.5507C3.93112 13.5736 4.6159 14.3659 5.5069 14.9357C6.39527 15.5028 7.42376 15.7863 8.58973 15.7863C9.90112 15.7863 10.9931 15.4577 11.8656 14.8005C12.7381 14.1433 13.3382 13.2158 13.6608 12.0154H15.7733C15.4507 13.7829 14.6602 15.1768 13.407 16.1997C12.1537 17.2226 10.5806 17.7314 8.68491 17.7314C7.06682 17.7314 5.64174 17.371 4.41232 16.6502C3.18289 15.9294 2.23636 14.9145 1.57273 13.6107C0.909107 12.3042 0.578613 10.7937 0.578613 9.07387C0.581257 7.37257 0.91175 5.86472 1.57538 4.55032Z" />
    <path d="M17.7642 0.563004H19.755V17.5919H17.7642V0.563004Z" />
    <path d="M24.1647 0.755348C24.4079 0.999148 24.5295 1.30655 24.5295 1.6802C24.5295 2.05385 24.4079 2.36125 24.1647 2.60505C23.9214 2.84885 23.6147 2.97075 23.2419 2.97075C22.8691 2.97075 22.5625 2.84885 22.3192 2.60505C22.076 2.36125 21.9543 2.05385 21.9543 1.6802C21.9543 1.30655 22.076 0.999148 22.3192 0.755348C22.5625 0.511548 22.8691 0.389648 23.2419 0.389648C23.6147 0.389648 23.9241 0.511548 24.1647 0.755348ZM22.2478 5.1835H24.2387V17.5908H22.2478V5.1835Z" />
    <path d="M35.7662 16.5553C34.8117 17.3423 33.5981 17.7345 32.1255 17.7345C30.9278 17.7345 29.8755 17.4669 28.9712 16.9316C28.0644 16.3963 27.3611 15.6463 26.8587 14.6817C26.3564 13.7171 26.1052 12.6094 26.1052 11.3613C26.1052 10.1131 26.359 9.01074 26.8693 8.05409C27.3796 7.09744 28.0961 6.35544 29.0162 5.82809C29.9389 5.30074 30.9991 5.03839 32.1968 5.03839C33.6537 5.03839 34.854 5.42794 35.8005 6.20704C36.7471 6.98614 37.3419 8.05674 37.5852 9.41884H35.5705C35.3775 8.62384 34.9703 7.99314 34.3569 7.52144C33.7409 7.05239 32.998 6.81654 32.1228 6.81654C30.941 6.81654 29.9839 7.23789 29.2462 8.08059C28.5086 8.92329 28.141 10.0177 28.141 11.3639C28.141 12.726 28.5086 13.8337 29.2462 14.6844C29.9812 15.535 30.941 15.9617 32.1228 15.9617C33.0297 15.9617 33.7885 15.7258 34.4045 15.2568C35.0179 14.7877 35.4145 14.1385 35.5943 13.3117H37.5852C37.3287 14.687 36.7206 15.7709 35.7662 16.5553Z" />
    <path d="M39.481 0.563004H41.4718V11.8494L47.7591 5.1846H50.1386L45.3822 10.27L50.4797 17.5919H48.0764L43.9465 11.7036L41.4718 14.3059V17.5893H39.481V0.563004Z" />
    <path d="M0.345664 21.8955H13.4754V23.8406H7.96548V38.9244H5.85297V23.8406H0.343018V21.8955H0.345664Z" />
    <path d="M24.6618 27.7319C25.5846 28.6409 26.0446 30.0666 26.0446 32.0143V38.9229H24.0537V32.1336C24.0537 30.8536 23.7629 29.8758 23.1812 29.2027C22.5996 28.5296 21.7641 28.193 20.6801 28.193C19.53 28.193 18.6205 28.5985 17.9489 29.4094C17.2773 30.2203 16.9416 31.3227 16.9416 32.7166V38.9202H14.9507V21.8913H16.9416V28.5561C17.3461 27.9068 17.8828 27.3795 18.5544 26.974C19.2259 26.5686 20.0482 26.3672 21.0185 26.3672C22.5255 26.3698 23.7391 26.823 24.6618 27.7319Z" />
    <path d="M28.4589 29.3866C28.9533 28.43 29.6434 27.688 30.5344 27.1606C31.4254 26.6333 32.4433 26.3709 33.5934 26.3709C34.725 26.3709 35.7297 26.6068 36.6022 27.0758C37.4747 27.5475 38.1621 28.2286 38.6645 29.119C39.1668 30.012 39.4339 31.0561 39.4656 32.2566C39.4656 32.4341 39.4497 32.7177 39.418 33.1072H29.8073V33.2768C29.839 34.4932 30.2118 35.4657 30.923 36.1971C31.6343 36.9259 32.5649 37.2916 33.715 37.2916C34.606 37.2916 35.3622 37.069 35.9835 36.6238C36.6075 36.1786 37.0226 35.5585 37.2341 34.7635H39.2488C39.0055 36.0275 38.4001 37.0637 37.4298 37.8666C36.4594 38.6696 35.2697 39.0697 33.8631 39.0697C32.6337 39.0697 31.5576 38.8074 30.6349 38.28C29.7121 37.7527 28.9956 37.008 28.488 36.0408C27.9777 35.0762 27.7239 33.9685 27.7239 32.7203C27.7213 31.4536 27.9671 30.3433 28.4589 29.3866ZM37.4033 31.4536C37.3055 30.3989 36.9142 29.5801 36.2268 28.9971C35.5394 28.4141 34.6695 28.1226 33.6172 28.1226C32.6786 28.1226 31.8537 28.43 31.1425 29.0474C30.4313 29.6649 30.0268 30.4678 29.9289 31.4563H37.4033V31.4536Z" />
    <path d="M47.622 26.5161V28.4612H46.6279C45.4461 28.4612 44.6 28.8587 44.0924 29.6537C43.5821 30.4487 43.3283 31.4292 43.3283 32.5978V38.9234H41.3374V26.5161H43.0612L43.3283 28.3896C43.6852 27.8225 44.1453 27.3667 44.7111 27.0275C45.2769 26.6857 46.0621 26.5161 47.0642 26.5161H47.622Z" />
    <path d="M60.4265 37.0966V38.9198H59.3345C58.5413 38.9198 57.9755 38.7581 57.6345 38.4322C57.2934 38.1089 57.1163 37.6292 57.1004 36.9959C56.1459 38.3739 54.7632 39.0629 52.9494 39.0629C51.5746 39.0629 50.4694 38.7396 49.6366 38.0903C48.8037 37.4411 48.386 36.5586 48.386 35.4377C48.386 34.1895 48.8117 33.2329 49.6604 32.5677C50.5091 31.9026 51.7438 31.5713 53.3619 31.5713H57.0026V30.7207C57.0026 29.9098 56.7302 29.2764 56.1882 28.8233C55.6462 28.3701 54.8901 28.1422 53.9197 28.1422C53.0605 28.1422 52.3545 28.333 51.7967 28.7146C51.2388 29.0962 50.8951 29.6024 50.7655 30.2357H48.7746C48.9201 29.0194 49.4568 28.0707 50.3874 27.3896C51.3181 26.7086 52.5264 26.3667 54.0149 26.3667C55.6013 26.3667 56.8254 26.7536 57.6926 27.5221C58.5572 28.2933 58.9908 29.3983 58.9908 30.8426V36.1956C58.9908 36.7945 59.2658 37.0966 59.8157 37.0966H60.4265ZM57.0026 33.2541H53.1689C51.3234 33.2541 50.4007 33.9431 50.4007 35.3211C50.4007 35.9385 50.6439 36.4314 51.1277 36.8051C51.6142 37.1787 52.2699 37.3642 53.0948 37.3642C54.3084 37.3642 55.2629 37.0436 55.9582 36.4023C56.6536 35.761 57.0026 34.9156 57.0026 33.8609V33.2541Z" />
    <path d="M71.0894 27.1235C71.9883 27.627 72.6916 28.3558 73.2019 29.3124C73.7121 30.2691 73.966 31.4033 73.966 32.7177C73.966 33.9658 73.7121 35.0709 73.2019 36.0275C72.6916 36.9842 71.983 37.7315 71.0788 38.2668C70.1719 38.8021 69.154 39.0697 68.0198 39.0697C67.0177 39.0697 66.1584 38.8869 65.4472 38.5212C64.736 38.1555 64.1755 37.6573 63.7736 37.0239V43.7867H61.7827V26.5167H63.5066L63.7736 28.5121C64.81 27.0864 66.2245 26.3709 68.0224 26.3709C69.1672 26.3709 70.1904 26.6227 71.0894 27.1235ZM70.7985 36.0169C71.552 35.1663 71.9275 34.0665 71.9275 32.7203C71.9275 31.3741 71.552 30.2744 70.7985 29.4237C70.045 28.5731 69.0562 28.1464 67.8241 28.1464C67.0309 28.1464 66.3223 28.3372 65.701 28.7188C65.0771 29.1004 64.5959 29.6357 64.2574 30.3247C63.9164 31.0137 63.7472 31.7955 63.7472 32.6726C63.7472 33.5657 63.9164 34.3633 64.2574 35.0682C64.5985 35.7731 65.0771 36.3217 65.701 36.7112C66.3223 37.1008 67.0309 37.2942 67.8241 37.2942C69.0535 37.2942 70.045 36.8676 70.7985 36.0169Z" />
    <path d="M76.076 29.3866C76.5704 28.43 77.2605 27.688 78.1515 27.1606C79.0425 26.6333 80.0604 26.3709 81.2105 26.3709C82.3421 26.3709 83.3468 26.6068 84.2193 27.0758C85.0918 27.5475 85.7819 28.2286 86.2816 29.119C86.7839 30.012 87.051 31.0561 87.0827 32.2566C87.0827 32.4341 87.0668 32.7177 87.0351 33.1072H77.4244V33.2768C77.4561 34.4932 77.8289 35.4657 78.5401 36.1971C79.2514 36.9259 80.182 37.2916 81.3321 37.2916C82.2231 37.2916 82.9793 37.069 83.6006 36.6238C84.2246 36.1786 84.6397 35.5585 84.8512 34.7635H86.8659C86.6226 36.0275 86.0172 37.0637 85.0442 37.8666C84.0739 38.6696 82.8841 39.0697 81.4775 39.0697C80.2481 39.0697 79.172 38.8074 78.2493 38.28C77.3266 37.7527 76.6101 37.008 76.0998 36.0408C75.5895 35.0762 75.3357 33.9685 75.3357 32.7203C75.3357 31.4536 75.5816 30.3433 76.076 29.3866ZM85.0178 31.4536C84.92 30.3989 84.5286 29.5801 83.8412 28.9971C83.1538 28.4141 82.284 28.1226 81.2317 28.1226C80.2931 28.1226 79.4682 28.43 78.7569 29.0474C78.0457 29.6649 77.6412 30.4678 77.5434 31.4563H85.0178V31.4536Z" />
    <path d="M99.6067 26.5161V38.9234H97.8829L97.6158 37.2698C96.6772 38.4702 95.3579 39.0691 93.6605 39.0691C92.1879 39.0691 91.006 38.616 90.1177 37.707C89.2266 36.7981 88.7825 35.3724 88.7825 33.4246V26.5161H90.7733V33.3027C90.7733 34.5853 91.0536 35.5605 91.6115 36.2336C92.1693 36.9067 92.9652 37.2433 94.0016 37.2433C95.1332 37.2433 96.0189 36.8378 96.6587 36.0269C97.2986 35.216 97.6185 34.1136 97.6185 32.7171V26.5161H99.6067Z" />
    <path d="M102.98 28.3397H100.746V26.5139H102.98V23.0344H104.971V26.5139H108.102V28.3397H104.971V35.6617C104.971 36.1811 105.072 36.5494 105.275 36.7694C105.476 36.9893 105.831 37.098 106.33 37.098H108.49V38.9212H106.209C105.043 38.9212 104.215 38.6615 103.721 38.1421C103.226 37.6227 102.98 36.8038 102.98 35.6855V28.3397Z" />
    <path d="M111.864 22.0879C112.107 22.3317 112.229 22.6391 112.229 23.0127C112.229 23.3864 112.107 23.6938 111.864 23.9376C111.62 24.1814 111.314 24.3033 110.941 24.3033C110.568 24.3033 110.261 24.1814 110.018 23.9376C109.775 23.6938 109.653 23.3864 109.653 23.0127C109.653 22.6391 109.775 22.3317 110.018 22.0879C110.261 21.8441 110.568 21.7222 110.941 21.7222C111.314 21.7222 111.62 21.8441 111.864 22.0879ZM109.947 26.516H111.938V38.9233H109.947V26.516Z" />
    <path d="M123.495 37.8878C122.54 38.6749 121.327 39.0671 119.854 39.0671C118.656 39.0671 117.604 38.7994 116.7 38.2641C115.793 37.7288 115.09 36.9789 114.587 36.0143C114.085 35.0497 113.834 33.942 113.834 32.6938C113.834 31.4457 114.088 30.3433 114.598 29.3866C115.108 28.43 115.825 27.688 116.745 27.1606C117.667 26.6333 118.728 26.3709 119.925 26.3709C121.382 26.3709 122.583 26.7605 123.529 27.5396C124.476 28.3187 125.07 29.3893 125.314 30.7514H123.299C123.106 29.9564 122.701 29.3257 122.085 28.854C121.469 28.3849 120.726 28.1491 119.851 28.1491C118.669 28.1491 117.712 28.5704 116.975 29.4131C116.237 30.2558 115.87 31.3503 115.87 32.6965C115.87 34.0586 116.237 35.1663 116.975 36.0169C117.71 36.8676 118.669 37.2942 119.851 37.2942C120.758 37.2942 121.517 37.0584 122.133 36.5893C122.749 36.1203 123.143 35.471 123.323 34.6442H125.314C125.057 36.0196 124.449 37.1034 123.495 37.8878Z" />
    <path d="M128.698 34.8349C128.729 35.5822 129.057 36.189 129.681 36.6607C130.303 37.1324 131.109 37.3656 132.095 37.3656C132.968 37.3656 133.676 37.1907 134.218 36.8436C134.76 36.4938 135.033 36.038 135.033 35.4682C135.033 34.9806 134.903 34.6096 134.644 34.3499C134.385 34.0902 134.049 33.9127 133.637 33.8146C133.224 33.7166 132.637 33.6212 131.876 33.5231C130.808 33.3933 129.935 33.2237 129.253 33.0117C128.573 32.8023 128.023 32.4631 127.603 32.002C127.183 31.5409 126.971 30.9023 126.971 30.0914C126.971 29.3626 127.177 28.7134 127.59 28.1463C128.002 27.5792 128.565 27.1366 129.277 26.8213C129.988 26.5059 130.789 26.3469 131.68 26.3469C133.137 26.3469 134.321 26.6888 135.236 27.3698C136.151 28.0509 136.656 29.0234 136.754 30.2901H134.763C134.681 29.6409 134.366 29.1056 133.816 28.6842C133.266 28.2629 132.579 28.0509 131.754 28.0509C130.913 28.0509 130.234 28.2258 129.716 28.5729C129.197 28.9227 128.938 29.3785 128.938 29.9483C128.938 30.3696 129.06 30.6956 129.303 30.9208C129.546 31.1487 129.858 31.3024 130.239 31.3819C130.62 31.4641 131.199 31.5515 131.973 31.6496C133.057 31.7794 133.951 31.957 134.654 32.1849C135.358 32.4128 135.924 32.7785 136.354 33.2793C136.783 33.7828 136.997 34.4639 136.997 35.3225C136.997 36.0698 136.778 36.7243 136.341 37.2941C135.905 37.8612 135.313 38.2984 134.57 38.6085C133.824 38.9159 132.999 39.0696 132.095 39.0696C130.477 39.0696 129.179 38.6959 128.201 37.9513C127.222 37.2066 126.725 36.1678 126.707 34.8375H128.698V34.8349Z" />
  </svg>
)

export default LogoText
