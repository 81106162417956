import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { splitStringByBreakTag } from "../../utils/array-helpers"
import { productNameOrDefault } from "../../utils/product-title"
import { Product } from "./footer-product"

export function FooterProducts() {
  const data = useStaticQuery(graphql`
    {
      allContentfulProductsPipelinePage(
        filter: { node_locale: { eq: "en-US" } }
      ) {
        distinct(field: { slug: SELECT })
        nodes {
          productDetailsBlock {
            product {
              productCode
              productDisorder
              productName
              landingPageUrl
            }
          }
        }
      }
    }
  `)
  const { productDetailsBlock } =
    data.allContentfulProductsPipelinePage?.nodes?.[0] ?? {}

  return (
    <React.Fragment>
      {productDetailsBlock?.map((d, index) => (
        <Product
          key={`${d.product.productCode}-${index}`}
          code={d.product.productCode}
          name={productNameOrDefault(d.product.productName)}
          description={splitStringByBreakTag(d.product.productDisorder, 0)}
          url={d.product.landingPageUrl}
        />
      )) ?? []}
    </React.Fragment>
  )
}
